import { event } from 'vue-gtag';
import {usePage} from '@inertiajs/vue3';

const page = usePage();

export function trackPageView(path) {
    event('page_view', {
        page_path: path,
        userId: page.props.session_id ?? '',
    });
}

export function trackUserInteraction(eventName, eventParams = {}) {
    event(eventName, {
        ...eventParams,
        userId: page.props.session_id,
    });
}
